
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface amount{
    id:Id,
    create_time:string
    order_no:string
    price:string
    amount:string
    user:string
}



class HttpAmount extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<amount>>({...data})
	}

    get_info = (id:number) => {
        return this.get<amount>({},`/admin/amount/${id}/`)
    }
}

export const api_amount =  new HttpAmount('/admin/amount/')
