







































































































































import { Mixin_list } from "@/mixin";
import { api_amount, amount } from "@/http/amount";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import DatePicker from "@/components/datePicker/index.vue";
import Table from "@/components/table/index.vue";

@Component({
  components: {
    Page,
    Table,
    DatePicker,
  },
})
export default class extends Mixin_list<amount>(api_amount.get_list) {
  filter = {
    id: "",
    phone: "",
    nickname: "",
    user_type: "",
    // create_time_before: "",
    // create_time_after: "",
    status: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "结算日期",
      prop: "create_time",
    },
    {
      label: "订单号",
      prop: "order_no",
    },
    {
      label: "结算金额",
      prop: "price",
    },
    {
      label: "佣金（元）",
      prop: "price",
    },
    {
      label: "分成",
      prop: "price",
    },
    {
      label: "合计",
      prop: "amount",
    },
  ];

  typeList = [
    {
      label: "全部",
      value: "",
    },
    {
      label: "微信",
      value: 1,
    },
    {
      label: "抖音",
      value: 2,
    },
  ];

  statusList = [
    {
      label: "全部",
      value: "",
    },
    {
      label: "禁止",
      value: 0,
    },
    {
      label: "正常",
      value: 1,
    },
  ];
  reset() {
    this.filter = { ...this.filter, ...this.resetFilter };
    (this.$refs.datePicker as Vue & { clearDates: () => void }).clearDates();
    this.find();
  }
  handleClick() {}
  // async handleClick( row:user){
  //     this.$confirm('确认升级吗?').then(async()=>{
  //         await api_user.edit({id:row.id,user_level:row.user_level === 1 ? 2 : row.user_level === 2 ? 3 : 0})
  //         this.get_list()
  //     })
  // }

  // click_cell(row: user) {
  //   return this.$router.push(`/user/detail/?id=${row.id}`);
  // }
}
